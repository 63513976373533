.footer {
  background-color: #ECEFF0;
  .brand {
    text-align: left;
  }

  .socialmedia a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 20px;
    border-color: $primary;
    width: 32px;
    height: 32px;
    color: $primary;

  }
}