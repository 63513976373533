@import '../partials/color';
@import '../partials/mixins';

.btn {
  font-family: 'Inter';
  padding: 10px 14px;
  font-size: 12px;
  border-radius: 8px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  
  &.border {
    border: 1px solid $primary;
    background-color: $white;
    color: $primary;
    // @include button_shadow;
  }

  &.primary {
    background-color: $primary;
    color: $white;
    // @include button_shadow;
  }

  &.alt {
    background-color: $alt;
    color: $white;
  }
}

.tab-content {
  display: none;

  &.active {
    display: block;
  }
}

.tab {
  font-family: 'Inter';
  padding: 10px 14px;
  font-size: 12px;
  border-radius: 8px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  background-color: $alt;
  color: $white;

  &.active {
    background-color: $primary;
  }
}



