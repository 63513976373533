@import '../partials/color';

.navbar {
  border-bottom: 1px solid $white;
  margin: 0;
  .nav-item {
    &.active {
      color: $primary;
    }

    a {
      color: inherit;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
  
      &:hover {
        color: $primary;

      }      
    }

    .dropdown {
      display: none;

      a {
        text-transform: capitalize;
      }

      &.show {
        display: flex;
      }
    }

  }
}

// .text-primary {
//   color: $primary;
// }

// .text-midgrey3 {
//   color: $midgrey-3;
// }


.dropdown {
  &.close {
    display: none;
  }
  &.open {
    display: flex;
  }
}

#drawer {
  .nav-item {
    padding: 8px 0;
    width: 100%;
  }

  .other-menu {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    .menu-item {      
      padding: 8px 16px;
      margin: 0;
      width: 100%;
      text-align: center;
      
      
      .btn {
        border-bottom: 1px solid $midgrey-1;
        border-radius: 0;
        border: none;
        box-shadow: none;
        background-color: transparent;
        color: $primary;
        display: block;
        padding: 10px 4px;
        text-align: left;
        border-radius: 0;
      }
    }
  }
}