.logo{

  .brand-image{
    background-image: url('./../images/logo/brand-pl-inverse.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 320px;
    height: 65px;
  }
}
