.table {
  border-top-left-radius: 1px solid $primary;
  border-top-right-radius: 1px solid $primary;
  
  th:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    
  }
  th:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    
  }
  tr {
    border-top: 1px solid $lightgray;
    &:first-child {
      border: none;
    }
  }

  thead tr {
    border: none;
  }
  
  td {
    padding: 16px;
  }

}