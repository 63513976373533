@import '../partials/color';


.accordion-wrapper {
  div {
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid $primary;
    overflow: hidden;
    
    input:checked + label:hover:after {
      background: none;
    }
    label {
      background-color: $white !important;
      color: $primary !important;
      font-weight: 600;
      padding: 16px !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
   
    }

    article {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      color: $midgrey-3;
      background-color: $white;
    }
  }
}

.accordion-wrapper div label::after,
.accordion-wrapper div input:checked + label::after {
  opacity: 1;
  position: relative;
  content: '+';
  width: unset;
  height: unset;
  top: 0;
  right: 0;
  background: none !important;
  padding-bottom: 4px;
}

.accordion-wrapper div label:hover:after, 
.accordion-wrapper div input:checked + label:hover:after {
  opacity: 1;
  position: relative;
  content: '+';
  width: unset;
  height: unset;
  top: 0;
  right: 0;
  background: none !important;
  padding-bottom: 4px;
}
