$primary: #0181F5;
$secondary: #03A9F4;
$alt: #081F49;
$white: #fff;
$defaultFont: #001A33;
$lightgray: #F4F4F4;
$bluegray: #33475B;
$midgrey-1: #B8C5CA;
$midgrey-2: #8D9AA1;
$midgrey-3: #7A8890;

.bg-lightgray {
  background-color: $lightgray;
}

.bg-bluegray {
  background-color: $bluegray;
}

.tabs .tab.active {
  color: #fff;
  background-color: $primary;
}

.tabs .tab {
  cursor: pointer;
}

.text-primary {
  color: $primary !important;
}